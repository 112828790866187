@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

/* Iltimos kim shu projectda ishlasa o'lchamlarni rem() qilib ishlasin */

$defaultSize: 16;
@function rem($pixels) {
  @return ($pixels/$defaultSize) + rem;
}

/* Iltimos ranglarni ham rootdan olib bering */

:root {
  --white: #ffffff;
  --darkWhite: #f1f1f1;
  --lightBlack: #2d3748;
  --black: #1a202c;
  --silver: #4a5568;
  --darkSilver: #494949;
  --lightSilver: #e8e8e8;
  --darkBlack: #000000;
  --green: #04c45c;
  --lightGreen: rgba(4, 196, 92, 0.1);
  --blue: #3999f2;
  --darkBlue: #3772ff;
  --yellow: #ca8a04;
  --lightRed: rgba(234, 179, 8, 0.1);
  --menuLink: #7a797d;
  --menuTitle: #5e5e5e;
  --newsHeader: #92929d;
  --borderColor: #e1e1fb;
  --postText: #11142d;
  --uploadBorder: #87898e;
  --activeMenuColor: #04c45c;
}
*::-webkit-scrollbar {
  width: rem(5);
  height: rem(5);
}
*::-webkit-scrollbar-track {
  background: var(--white);
}
*::-webkit-scrollbar-thumb {
  background: var(--green);
}

.max-width {
  max-width: rem(1366);
  margin: 0 auto;
}
.d-none {
  display: none !important;
}

.main {
  width: 100%;
  // height: 100vh;
  overflow-y: auto;
  &_page {
    background-color: #f8f8f8 !important;
    padding: rem(37) rem(24);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}

.searchNews {
  margin-right: rem(30);
  .ant-input-group {
    input {
      border: 0;
    }
    .ant-input-group-addon {
      background: transparent !important;
      button {
        border: 0 !important;
        box-shadow: 0;
        span {
          svg {
            width: rem(20);
            height: rem(20);
            fill: var(--darkBlack);
          }
        }
      }
    }
  }
}

.postPagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      border: 0;
      background: var(--green);
      border-radius: 50%;
      // width: rem(30);
      // height: rem(30);
      margin: 0 rem(3) 0 0;
      span {
        svg {
          fill: var(--white);
        }
      }
    }
  }
  .ant-pagination-next {
    margin: 0 0 0 rem(3);
  }
  .ant-pagination-item {
    border: 0;
    border-radius: 50%;
    a {
      font-size: rem(16);
      line-height: rem(16);
      font-weight: 500;
      color: var(--darkSilver);
      margin-top: rem(8);
    }
  }
  .ant-pagination-item-active {
    background: var(--green);
    border-radius: 50%;
    a {
      color: var(--white);
      font-size: rem(16);
      line-height: rem(16);
      font-weight: 500;
      margin-top: rem(8);
    }
  }
}

.createPostForm {
  padding-left: rem(32);
  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: rem(16);
      label {
        font-size: rem(16);
        font-weight: 700;
        line-height: 120%;
        color: var(--postText);
      }
    }
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-select {
            width: rem(334);
            height: rem(56);
            .ant-select-selector {
              border: 0;
              height: 100%;
              display: flex;
              align-items: center;
              .ant-select-selection-item {
                font-size: rem(16);
                font-weight: 700;
                line-height: 120%;
                color: var(--silver);
              }
            }
          }
          .tagsSelect {
            height: max-content;
            overflow-x: hidden;
            .ant-select-selector {
              padding: rem(5) rem(10);
              .ant-select-selection-overflow {
                .ant-select-selection-overflow-item {
                  .ant-select-selection-search {
                    margin: 0;
                  }
                  .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                    margin: rem(3) rem(3) 0 0;
                    padding: 0 rem(9) 0 rem(13);
                    height: rem(40);
                    border-color: var(--green);
                    background: var(--green);
                    .ant-select-selection-item-content {
                      font-size: rem(16);
                      font-weight: 700;
                      line-height: 120%;
                      color: var(--white);
                      letter-spacing: rem(0.45);
                    }
                    .ant-select-selection-item-remove {
                      span {
                        margin-left: rem(3);
                        svg {
                          fill: var(--white);
                          width: rem(15);
                          height: rem(15);
                        }
                      }
                    }
                  }
                }
              }
              .ant-select-selection-placeholder {
                font-size: rem(16);
                font-weight: 700;
                line-height: 120%;
                color: var(--silver);
              }
            }
          }
          input {
            width: rem(334);
            height: rem(56);
            font-size: rem(16);
            font-weight: 700;
            line-height: 120%;
            color: var(--postText);
            border: 0;
            &::placeholder {
              font-size: rem(16);
              font-weight: 700;
              line-height: 120%;
              // color: var(--postText);
            }
          }
          .ant-btn {
            border-radius: rem(8);
            background: var(--green);
            width: rem(134);
            height: rem(48);
            font-weight: 700;
            font-size: rem(16);
            line-height: 120%;
            letter-spacing: 0.005em;
          }
          .uploadInput {
            height: rem(100);
            &::-webkit-file-upload-button {
              visibility: hidden;
            }
            &::before {
              content: "";
              position: absolute;
              width: rem(334) !important;
              height: rem(100) !important;
              background: var(--lightSilver);
              z-index: 2;
              border-radius: rem(8);
              cursor: pointer;
              border: rem(1) dashed var(--uploadBorder);
            }
            &::after {
              background: url(./Assets/Images/Upload.png) no-repeat center
                center;
              background-size: cover;
              position: absolute;
              content: "";
              z-index: 2;
              width: rem(69);
              height: rem(60);
              top: 27%;
              left: 27%;
            }
          }
          .ant-upload-list {
            .ant-upload-list-text-container {
              .ant-upload-list-item {
                height: rem(40);
                border-radius: rem(8);
                .ant-upload-list-item-info {
                  .ant-upload-span {
                    padding-left: rem(5);
                    .ant-upload-list-item-card-actions {
                      button {
                        width: rem(30);
                        height: rem(30);
                        margin-right: rem(5);
                      }
                    }
                  }
                }
              }
            }
          }
          .uploadImage,
          .ant-upload-list-text {
            width: rem(334);
          }
        }
      }
    }
  }
}

.customInput {
  border-radius: 12px;
}

.postDropdownEdit {
  border: 0;
  width: rem(20) !important;
  height: rem(26) !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rem(2) rem(2) rem(2) transparent !important;
  svg {
    width: rem(20) !important;
    height: rem(20) !important;
  }
}

.deletePost {
  margin: 0;
  svg {
    margin-right: rem(5);
  }
}

.customMiniButton {
  background: #ffffff;
  border-radius: 6px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  > label {
    cursor: pointer;
  }
}
.searchPost {
  border: 0;
  height: max-content;
  width: max-content;
  padding: 0;
  margin-right: rem(30);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchPostInput {
  opacity: 0;
  cursor: default;
  transition: 0.3s ease-in-out;
}
