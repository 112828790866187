@import "../../App.scss";

.login {
  width: 100%;
  height: 100vh;
  &_row {
    height: 100% !important;
    overflow: hidden;
  }
  &_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &_col {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      display: flex;
      flex-direction: column;
      width: rem(360);
      span {
        font-size: rem(16);
        line-height: rem(19);
        font-weight: 400;
        color: var(--lightBlack);
        margin-bottom: 2px;
      }
      font-size: rem(32);
      line-height: rem(35);
      font-weight: 700;
      color: var(--black);
      margin-bottom: rem(24);
    }
    &_form {
      width: rem(360);
      .ant-form-item {
        .ant-form-item-label {
          label {
            font-size: rem(16) !important;
            line-height: rem(19) !important;
            font-weight: 400 !important;
            color: var(--silver) !important;
          }
        }
      }
      input {
        border: rem(1) solid var(--lightSilver);
        border-radius: rem(5);
        height: rem(50);
        // font-size: rem(22);
        // padding: 0;
        &::placeholder {
          font-size: rem(14);
          line-height: rem(16);
          color: var(--lightBlack);
        }
      }
      button {
        background: var(--green);
        width: 100%;
        border: 0;
        height: rem(50);
        border-radius: rem(5);
        font-size: rem(16);
        line-height: rem(19);
        font-weight: 700;
      }
    }
  }
}
