@import "../../App.scss";

.news {
  height: 100vh;
  &_upload_image {
    background: #f1f1f1;
    border: 1px dashed rgba(114, 114, 114, 0.3);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    width: 400px;
    &_edit {
      position: relative;
      width: 400px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        z-index: 1;
        transition: 0.3s;
      }
      > div {
        transition: 0.3s;
        z-index: 1;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        gap: 0 10px;
        > button {
          width: 50px !important;
          background: transparent !important;
          &:hover,
          &:focus {
            background: transparent;
            border-color: #ffffff;
          }
          svg {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
      &:hover {
        &::before {
          background: rgba(0, 0, 0, 0.8);
        }
        > div {
          opacity: 1;
        }
      }
    }
    &_accept,
    &_reject {
      border: 3px dashed rgba(114, 114, 114, 0.3);
    }
    > h1 {
      font-style: normal;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #434343 !important;
      margin-top: 10px;
      > span {
        color: rgba(57, 153, 242, 1);
        text-decoration: underline;
      }
    }
  }
  h1 {
    line-height: rem(42);
    font-size: rem(32);
    font-weight: 700;
    color: var(--darkSilver);
  }
}
.post {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: rem(8);
  &_header {
    display: flex;
    border-bottom: rem(1) solid #e1e1fb;
    // width: 100%;
    margin: rem(18) 0 rem(14) 0;
    height: rem(48);
    justify-content: space-between;
    align-items: center;
    padding-bottom: rem(20);
    div {
      display: flex;
      align-items: center;
      margin: 0 rem(30);
      input {
        border-radius: rem(8);
        margin-right: rem(30);
        height: rem(46);
      }
    }
    &_btn {
      background: transparent;
      width: max-content;
      margin: 0;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      font-weight: 700;
      line-height: 120%;
      font-size: rem(16);
      &:first-child {
        margin-right: rem(40);
        color: var(--newsHeader);
      }
      &:nth-child(2) {
        color: var(--newsHeader);
      }
      &_active {
        color: var(--green) !important;
      }
    }
    &_createBtn {
      border-radius: rem(8);
      width: rem(160);
      height: rem(48);
      font-weight: 700;
      font-size: rem(16);
      line-height: 120%;
    }
  }
  &_pagination {
    padding: rem(21) rem(30);
    border-top: rem(1) solid #e1e1fb;
  }
}
.postCard {
  //   width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: rem(1) solid var(--borderColor);
  border-radius: rem(8);
  padding: rem(4) rem(20);
  margin: 0 rem(30) rem(8) rem(30);
  transition: all 0.3s ease-in-out;
  div {
    h3 {
      margin-bottom: rem(11);
      color: var(--darkBlack);
      font-size: rem(12);
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.005em;
    }
    p {
      letter-spacing: 0.008em;
      margin: 0;
      color: var(--darkBlack);
      font-size: rem(12);
      line-height: 120%;
      display: flex;
      align-items: center;
      svg {
        margin: 0 rem(14) rem(2) 0;
      }
    }
  }
}
.createPost {
  height: 100vh;
  h1 {
    line-height: rem(42);
    font-size: rem(32);
    font-weight: 700;
    color: var(--darkSilver);
  }
  &_text {
    background: var(--white);
    padding: rem(20);
    border-radius: rem(8);
    height: 100%;
    position: relative;
    border: rem(1) solid var(--borderColor);
    input {
      border-radius: 0;
      border: rem(1) solid var(--borderColor);
      height: rem(64);
      color: var(--darkBlack);
      font-size: rem(16);
      font-weight: 700;
      line-height: 120%;
      margin-bottom: rem(7);
      &::placeholder {
        // color: var(--newsHeader);
        opacity: 0.8;
        font-size: rem(16);
        font-weight: 700;
        line-height: 120%;
      }
    }
    textarea {
      height: 85%;
      border: rem(1) solid var(--borderColor);
      font-size: rem(14);
      line-height: 130%;
      letter-spacing: 0.008em;
      color: var(--postText);
      padding: rem(24) rem(16);
      &::placeholder {
        font-size: rem(14);
        line-height: 130%;
        letter-spacing: 0.008em;
        // color: var(--newsHeader);
      }
    }
  }
  &_tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(16);
    div {
      cursor: pointer;
      border-radius: rem(12);
      width: max-content;
      background: var(--darkSilver);
      color: var(--white);
      letter-spacing: 0.008em;
      font-weight: 700;
      font-size: rem(12);
      line-height: 110%;
      padding: rem(6) rem(12);
      margin: 0 rem(8) rem(5) 0;
      transition: 0.3s ease-in-out;
      &:active {
        transform: scale(0.9);
      }
    }
  }
}
