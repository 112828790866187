$defaultSize: 16;
@function rem($pixels) {
  @return ($pixels/$defaultSize) + rem;
}

:global {
  .building_block_svg_modal {
    .ant-modal-content {
      border-radius: rem(15);
    }
    .ant-modal-header {
      border-radius: rem(15) rem(15) 0 0;
    }
    .ant-modal-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        cursor: pointer;
      }
    }
    .ant-modal-body {
      .ant-space {
        width: 100%;
        justify-content: space-between;
        gap: 32px !important;
        .ant-space-item {
          width: 50%;
        }
      }
    }
    &_footer {
      display: flex;
      justify-content: flex-end;
      > button {
        border-radius: rem(50);
        width: rem(232);
        height: rem(56);
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(16);
        border: none;
        &:nth-child(1) {
          background: #f1f1f1;
          color: #23262f;
          margin-right: 16px;
          &:hover,
          &:focus {
            background: #f1f1f1;
            color: #23262f;
          }
        }
        &:nth-child(2) {
          background: #3772ff;
          color: #ffffff;
          &:hover,
          &:focus {
            background: #3772ff;
            color: #ffffff;
          }
        }
      }
    }
    &_imageProgress {
      display: flex;
      width: 100%;
      height: rem(75);
      background: #fcfcfd;
      border: rem(1) solid #dfdfe6;
      border-radius: rem(12);
      justify-content: space-between;
      align-items: center;
      padding: 0 rem(16);
      margin-bottom: 32px;
      > span {
        background: #ebeff7;
        border-radius: 50%;
        min-width: rem(43);
        height: rem(43);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > b {
        cursor: pointer;
      }
      > div {
        width: calc(100% - rem(123));
        > p {
          margin-bottom: 0;
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          font-size: rem(12);
          line-height: rem(16);
          color: #87898e;
        }
      }
    }
    &_flats {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      user-select: none;
      &_item {
        display: flex;
        width: rem(450);
        height: rem(60);
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(96, 108, 128, 0.05);
        border-radius: 8px;
        justify-content: space-between;
        align-items: center;
        > h1 {
          font-weight: 600;
          font-size: rem(14);
          line-height: rem(20);
          color: #6b6b6b;
          > span {
            color: #3999f2;
          }
        }
        > div {
          display: flex;
          align-items: center;
          gap: 0 18px;
          > span {
            cursor: pointer;
            color: #3999f2;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
    &_additional_info {
      background: #ffffff;
      border-radius: 12px;
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 rem(16);
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(16);
        color: #3999f2;
        cursor: pointer;
        &:hover {
          background: #ecf5fe;
        }
      }
    }
    &_imageCustomUpload {
      margin-bottom: 32px;
      position: relative;
      > div {
        display: flex;
        align-items: center;
        gap: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: 0.5s;
        > button {
          background: transparent;
          &:hover,
          &:focus {
            background: transparent;
            border-color: #ffffff;
          }
          svg {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: #00000000;
        transition: 0.5s;
      }
      &:hover {
        &::before {
          background: #00000060;
        }
        > div {
          opacity: 1;
        }
      }
    }
    &_table {
      width: 100%;
      margin: 32px 0;
      th {
        text-align: left;
      }
      tr {
        height: 44px;
      }
      td {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(16);
        color: #3999f2;
        > span {
          margin-left: 8px;
        }
        > b {
          cursor: pointer;
        }
      }
      td:nth-child(2),
      th:nth-child(2) {
        width: 200px;
        text-align: right;
        padding-right: 20px;
      }
      th:nth-child(2) {
        padding-right: 60px;
      }
      th:last-child,
      td:last-child {
        text-align: center;
      }
    }
  }
}

.buildingId {
  > h1 {
    font-weight: 700;
    font-size: rem(32);
    line-height: rem(39);
    color: #494949;
  }
  > p {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(19);
    color: #7a797d;
  }
  &_uploadImages {
    display: flex;
    &_right {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  &_fullImageUpload {
    cursor: pointer;
    background: #f1f1f1;
    border: 1px dashed rgba(114, 114, 114, 0.3);
    border-radius: 4px;
    width: 445px;
    height: 202px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    > h1 {
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(24);
      color: #434343;
      > span {
        color: #3999f2;
        text-decoration: underline;
        margin-left: 4px;
      }
    }
    > svg {
      margin-bottom: 25px;
    }
    &_accept,
    &_reject {
      border: 3px dashed rgba(144, 17, 17, 0.3);
    }
  }
  &_body {
    > h1 {
      font-weight: 700;
      font-size: rem(16);
      line-height: rem(21);
      color: #7a797d;
      margin: rem(20) 0;
    }
    &_container {
      display: flex;
      align-items: flex-start;
      gap: 0 50px;
      overflow-x: auto;
      padding-bottom: 10px;
      height: calc(100vh - rem(430));
      &_col {
        min-width: 375px;
        border: 1px solid #cbcbcb;
        border-radius: 6px;
        padding: 6px;
        user-select: none;
        max-height: 100%;
        overflow-y: auto;
        &_header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 44px;
          padding: 0 18px;
          background: #ffffff;
          border-radius: 6px;
          cursor: pointer;
          > h1 {
            font-weight: 700;
            font-size: rem(16);
            line-height: rem(20);
            margin-bottom: 0;
            color: #333333;
            padding: 0 12px;
            > span {
              font-weight: 800;
              font-size: rem(10);
              line-height: rem(16);
              text-align: center;
              color: #ca8a04;
              margin-left: 12px;
              background: rgba(234, 179, 8, 0.1);
              border-radius: 16px;
              padding: 4px 8.5px;
            }
          }
          > div {
            display: flex;
            align-items: center;
            gap: 18px;
            > span {
              color: #3999f2;
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
            }
          }
        }
        &_container {
          &_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 44px;
            padding: 0 22px;
            background: #ffffff;
            border-radius: 6px;
            cursor: pointer;
            margin-top: 4px;
            &:first-child {
              margin-top: 12px;
            }
            > h1 {
              font-weight: 700;
              font-size: rem(14);
              line-height: rem(28);
              margin-bottom: 0;
              color: #6b6b6b;
              padding: 0 12px;
              > span {
                font-weight: 800;
                font-size: rem(10);
                line-height: rem(16);
                text-align: center;
                color: #ca8a04;
                margin-left: 12px;
                background: rgba(234, 179, 8, 0.1);
                border-radius: 16px;
                padding: 4px 8.5px;
              }
            }
            > div {
              display: flex;
              align-items: center;
              gap: 18px;
              > span {
                color: #3999f2;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
