@import "../../App.scss";

.menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  position: sticky;
  top: 0;
  &_links {
    display: flex;
    flex-direction: column;
    padding: 0 0 rem(47) rem(47);
    a {
      display: flex;
      align-items: center;
      font-size: rem(16);
      line-height: rem(21);
      font-weight: 500;
      width: max-content;
      color: var(--menuLink);
      &:first-child {
        margin-bottom: rem(24);
      }
      svg {
        margin-right: rem(19);
      }
    }
  }
  &_title {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: rem(32);
      font-weight: 700;
      line-height: rem(42);
      color: var(--menuTitle);
      margin: rem(37) 0 0 rem(47);
    }
    a {
      display: flex;
      align-items: center;
      color: var(--menuLink);
      font-size: rem(16);
      line-height: rem(21);
      font-weight: 700;
      margin: rem(32) 0 0 rem(47);
      position: relative;
      &:nth-of-type(1) {
        margin-top: rem(68);
      }

      svg {
        margin-right: rem(18);
        > path {
          stroke: var(--menuLink);
          &:nth-child(1) {
            fill: var(--menuLink);
          }
        }
      }
    }
    .active {
      color: var(--green);
      &:after {
        width: rem(7);
        height: rem(7);
        border-radius: 50%;
        content: "";
        position: absolute;
        background: var(--green);
        left: rem(-22);
      }
      > svg {
        > path {
          stroke: var(--green);
          &:nth-child(1) {
            fill: var(--green);
          }
        }
      }
    }
  }
  &_logout {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(42);
    width: 100%;
    > a {
      padding: rem(12) 0;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(21);
      color: #7a797d;
      > svg {
        margin: 0 rem(19) 0 rem(50);
      }
    }
  }
}
